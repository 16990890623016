//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'index-news-list',
  props: {
    news: {
      type: Object,
      default: () => {},
    },
  },
};
