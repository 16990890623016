//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReturnModal from 'razlet-sdk/lib/components/avia/return/return-modal';

export default {
  name: 'info-buttons',
  components: {
    ReturnModal,
  },
  data() {
    return {
      isReturnWindowOpened: false,
      typeReturn: null,
    };
  },
  computed: {
    questions() {
      return [
        {
          icon: 'rz-icon-ticket-check',
          text: this.$t('rru.infoButtons[0]'),
          click: 'findOrder',
        },
        {
          icon: 'rz-icon-ticket-return',
          text: this.$t('rru.infoButtons[1]'),
          click: 'returnTicket',
        },
        {
          icon: 'rz-icon-pass',
          text: this.$t('rru.infoButtons[2]'),
          click: 'exchangePass',
        },
        {
          icon: 'rz-icon-vpd',
          text: this.$t('rru.infoButtons[3]'),
          click: 'goToVPD',
        },
      ];
    },
  },
  methods: {
    actionCard(action) {
      this[action]();
    },
    returnTicket() {
      if (this.isCorporate) {
        this.$router.push({ name: 'account-requests-create', query: { template: 'empty', types: 'tickets' } });
      } else {
        this.typeReturn = 'exchange';
        this.isReturnWindowOpened = true;
      }
    },
    findOrder() {
      this.typeReturn = 'search';
      this.isReturnWindowOpened = true;
    },
    exchangePass() {
      if (this.isCorporate) {
        this.$router.push({ name: 'account-requests-create', query: { template: 'empty', types: 'tickets' } });
      } else {
        this.typeReturn = 'exchangePass';
        this.isReturnWindowOpened = true;
      }
    },
    goToVPD() {
      this.$router.push('/vpd');
    },
  },
};
